import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FRONT_URL } from "../utils/config";
import { API_URL } from "../utils/config";

function Service(props) {
  const [backendData, setBackendData] = useState([]);

  useEffect(() => {
    fetch(`${API_URL}/website/services`)
      .then((response) => response.json())
      .then((data) => {
        setBackendData(data);
      })
      .catch((error) => {
        console.error("Error fetchin data :", error);
      });
  }, []);

  return (
    <section className="row mt-5 d-flex justify-content-center">
      <div className="text-center mb-5">
        <h2>Mes services</h2>
      </div>

      {backendData.map((service, index) => (
        <div
          key={index}
          className="card p-0 col-12 col-md-4 mb-3 ms-3 me-3"
          style={{ borderRadius: "1.5em", width: "18rem", overflow: "hidden" }}
        >
          <div>
            <img
              src={`${FRONT_URL}/${service.urlIllustration}`}
              className="card-img-top"
              alt={service.name}
            />
          </div>

          <div className="card-body d-flex flex-column align-items-center">
            <h5 className="card-title mb-3">{service.name}</h5>
            <Link
              to={`/${service.name.toLowerCase().replace(/[\sà]/g, "")}`}
              className="btn background_blue"
            >
              En savoir plus
            </Link>
          </div>
        </div>
      ))}
    </section>
  );
}

export default Service;
