import axios from "axios";
import { Field, Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../context";
import { jwtDecode } from "jwt-decode";
import Cookies from "universal-cookie";
import { API_URL } from "../utils/config";

const cookies = new Cookies();

function Login() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [backendErrors, setBackendErrors] = useState([]);
  const { dispatch } = useContext(Context);

  const navigate = useNavigate();

  return (
    <div className="mt-5">
      <div
        className="container mt-4 mb-4 pt-3 col-10 col-md-8 col-lg-5 mx-auto background_blue"
        style={{ borderRadius: "8px" }}
      >
        <h1 className="text-center">Connexion</h1>
        <div className="d-flex flex-row mb-2 justify-content-around"></div>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              const { email, password } = values;
              const response = await axios.post(`${API_URL}/login`, {
                email,
                password,
              });

              // Store the token and user data
              const token = response.data;
              const decode = jwtDecode(token);

              const userResponse = await axios.get(
                `${API_URL}/users/${decode.userId}`,
                {
                  headers: {
                    "x-access-token": token,
                  },
                }
              );

              // Store the token in cookies
              cookies.set("authToken", token, { path: "/" });

              // Dispatch the action to update context
              dispatch({
                type: "AUTH_SUCCESS",
                payload: { user: userResponse.data, token },
              });

              setSubmitting(false);
              // Check if the user is admin and navigate accordingly
              if (userResponse.data.isAdmin) {
                navigate("/admin/home");
              } else {
                navigate("/profile");
              }
            } catch (error) {
              if (error.response) {
                if (error.response.status === 401) {
                  setBackendErrors([error.response.data.message]);
                } else if (error.response.data.errors) {
                  setBackendErrors(
                    error.response.data.errors.map((err) => err.msg)
                  );
                }
              } else {
                setBackendErrors([
                  "Une erreur s'est produite. Veuillez réessayer.",
                ]);
                console.error(error);
              }
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form className="pb-3 pt-3">
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email*
                </label>
                <Field
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  aria-describedby="emailHelp"
                  required
                />
              </div>
              <div className="mb-3 ">
                <label htmlFor="password" className="form-label">
                  Mot de passe*
                </label>
                <Field
                  type="password"
                  className="form-control"
                  name="password"
                  id="password"
                  required
                />
              </div>
              {backendErrors.length > 0 && (
                <div className="alert alert-danger">
                  {backendErrors.map((error, index) => (
                    <div key={index}>{error}</div>
                  ))}
                </div>
              )}
              <div className="text-end">
                <button
                  type="submit"
                  className="btn background_dark text-white "
                  disabled={isSubmitting}
                >
                  Se connecter
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div className="container p-0 pt-3 col-10 col-md-8 col-lg-5 mx-auto d-flex justify-content-between pb-5">
        <div>
          <Link className="text-dark fs-5" to="/sign-up">
            Pas encore de compte ?
          </Link>
        </div>
        <div className="text-end pb-5">
          <Link className="text-dark fs-5" to="/forgot-password">
            Mot de passe oublié ?
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Login;
