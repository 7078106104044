import React from "react";
import logo from "../Logobaseline_-_couleurs_-_fond_blanc.png";
import Navbar from "./Navbar";
import { Link, NavLink } from "react-router-dom";

function Header(props) {
  return (
    <div className="container p-0 text-center mt-2">
      <div className="row">
        <div className="col-12 col-md-2">
          <Link to={"/"}>
            <img src={logo} alt="logo Zooethos" className="logo" width="240" />
          </Link>
        </div>
        <div className="col-12 col-md-10 mt-2 mt-md-4 justify-content-center">
          {props.isMobile ? (
            <div className="row align-items-center">
              <div className="col-4 link-underline-opacity-0">
                <Link
                  to={"/educationcanine"}
                  className="fs-6 h2 bleu_zooethos link-underline link-underline-opacity-0"
                >
                  ÉDUCATION CANINE
                </Link>
              </div>
              <div className="col-4">
                <Link
                  to={"/baladecanine"}
                  className="fs-6 h2 bleu_zooethos link-underline link-underline-opacity-0"
                >
                  {" "}
                  BALADE CANINE
                </Link>
              </div>
              <div className="col-4">
                <Link
                  to={"/gardedomicile"}
                  className="fs-6 h2 bleu_zooethos link-underline link-underline-opacity-0"
                >
                  {" "}
                  GARDE À DOMICILE
                </Link>
              </div>
            </div>
          ) : (
            <div className="row align-items-center">
              <div className="col-4">
                <Link
                  to={"/educationcanine"}
                  className="fs-1 h2 link-underline link-underline-opacity-0 bleu_zooethos"
                >
                  ÉDUCATION CANINE
                </Link>
              </div>
              <div className="col-4">
                <Link
                  to={"/baladecanine"}
                  className="fs-1 h2 link-underline link-underline-opacity-0 bleu_zooethos"
                >
                  {" "}
                  BALADE CANINE
                </Link>
              </div>
              <div className="col-4">
                <Link
                  to={"/gardedomicile"}
                  className="fs-1 h2 link-underline link-underline-opacity-0 bleu_zooethos"
                >
                  {" "}
                  GARDE À DOMICILE
                </Link>
              </div>
            </div>
          )}

          <h3 className="mt-3">SEINE-MARITIME & EURE</h3>
        </div>
      </div>
      <hr />
      <Navbar />
    </div>
  );
}

export default Header;
