import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import Mozaic from "../components/Mozaic";
import Footer from "../components/Footer";
import { API_URL } from "../utils/config";
import axios from "axios";
import { Link } from "react-router-dom";

function Contact(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [backendErrors, setBackendErrors] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isConsentChecked, setIsConsentChecked] = useState(false);

  return (
    <div>
      <div className="container">
        <div className="row ">
          <div className="col-12 col-md-6 mx-auto d-flex align-items-center">
            <div className=" p-3 col-12 ">
              <div className="row d-flex flex-wrap">
                <div className="m-3">
                  <h1 className="text-center">Contactez Zooethos :</h1>
                </div>

                <Formik
                  initialValues={{
                    firstName: "",
                    lastName: "",
                    city: "",
                    email: "",
                    subject: "",
                    message: "",
                  }}
                  onSubmit={async (values, { setSubmitting }) => {
                    try {
                      const {
                        firstName,
                        lastName,
                        email,
                        city,
                        subject,
                        message,
                      } = values;
                      await axios.post(`${API_URL}/website/contact`, {
                        firstName,
                        lastName,
                        email,
                        city,
                        subject,
                        message,
                      });

                      setSubmitting(false);
                      setShowModal(true);
                    } catch (error) {
                      if (error.response) {
                        if (error.response.status === 409) {
                          setBackendErrors([error.response.data.message]);
                        } else if (error.response.data.errors) {
                          setBackendErrors(
                            error.response.data.errors.map((err) => err.msg)
                          );
                        }
                      } else {
                        setBackendErrors([
                          "Une erreur s'est produite. Veuillez réessayer.",
                        ]);
                      }
                      setSubmitting(false);
                    }
                  }}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <div className="mb-3">
                        <label htmlFor="lastName" className="form-label">
                          Nom
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          id="lastName"
                          name="lastName"
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="firstName" className="form-label ">
                          Prénom
                        </label>
                        <Field
                          type="text"
                          className="form-control "
                          id="firstName"
                          name="firstName"
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="city" className="form-label ">
                          Commune
                        </label>
                        <Field
                          type="text"
                          className="form-control "
                          id="city"
                          name="city"
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="email" className="form-label ">
                          Email
                        </label>
                        <Field
                          type="email"
                          className="form-control "
                          id="email"
                          name="email"
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="subject" className="form-label ">
                          Sujet
                        </label>
                        <Field
                          component="select"
                          className="form-control "
                          id="subject"
                          name="subject"
                          required
                        >
                          <option value="">Choisissez un sujet</option>
                          <option value="Education canine">
                            Education canine
                          </option>
                          <option value="Balade canine">Balade canine</option>
                          <option value="Garde à domicile">
                            Garde à domicile
                          </option>
                        </Field>
                      </div>
                      <div className="mb-3">
                        <label htmlFor="message" className="form-label ">
                          Corps du message
                        </label>
                        <Field
                          as="textarea"
                          className="form-control"
                          id="message"
                          name="message"
                          rows="4"
                          required
                        />
                      </div>
                      <label className="containerCheckbox">
                        <Field
                          className="checkbox"
                          type="checkbox"
                          name="consent"
                          required
                          checked={isConsentChecked}
                          onChange={(e) => {
                            setIsConsentChecked(e.target.checked);
                          }}
                        />
                        Je consens à l’utilisation de mes informations
                        personnelles pour répondre à ma demande et accepte la{" "}
                        <Link to={"/mentions-légales"}>
                          Politique de Confidentialité
                        </Link>
                        .<span className="checkmark"></span>
                      </label>
                      {backendErrors.length > 0 && (
                        <div className="alert alert-danger">
                          {backendErrors.map((error, index) => (
                            <div key={index}>{error}</div>
                          ))}
                        </div>
                      )}
                      <button
                        type="submit"
                        className="btn background_blue "
                        disabled={isSubmitting || !isConsentChecked}
                      >
                        Envoyer
                      </button>
                    </Form>
                  )}
                </Formik>

                <div
                  className={`modal mt-5 pt-5 ${showModal ? "show" : ""}`}
                  tabindex="-1"
                  style={{ display: showModal ? "block" : "none" }}
                >
                  <div className="modal-dialog text-align-end">
                    <div className="modal-content text-align-end">
                      <div className="modal-header background_blue">
                        <h5 className="modal-title">Contact</h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={() => setShowModal(false)}
                        ></button>
                      </div>
                      <div className="modal-body">
                        <p>Message envoyé avec succés.</p>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn background_blue"
                          data-bs-dismiss="modal"
                          onClick={() => setShowModal(false)}
                        >
                          Fermer
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {props.isMobile ? (
            <div></div>
          ) : (
            <div className="col-6 mx-auto text-center d-flex align-items-center">
              <Mozaic />
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
