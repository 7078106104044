import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import { Context } from "../context";
import { jwtDecode } from "jwt-decode";
import Cookies from "universal-cookie";
import { API_URL } from "../utils/config";

const cookies = new Cookies();

function SignUp() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [areaCode, setAreaCode] = useState("");
  const [communes, setCommunes] = useState([]);
  const [selectedCommune, setSelectedCommune] = useState("");
  const [backendErrors, setBackendErrors] = useState([]);
  const { dispatch } = useContext(Context);

  useEffect(() => {
    if (areaCode.length === 5) {
      axios
        .get(`https://apicarto.ign.fr/api/codes-postaux/communes/${areaCode}`)
        .then((response) => {
          if (response.data.length > 0) {
            setCommunes(response.data);
          } else {
            setCommunes([]);
          }
        })
        .catch((error) => {
          console.error("Erreur lors de la récupération des communes:", error);
          setCommunes([]);
        });
    } else {
      setCommunes([]);
    }
  }, [areaCode]);

  const handleAreaCodeChange = (e, setFieldValue) => {
    const value = e.target.value;
    setAreaCode(value);
    setFieldValue("areaCode", value);
  };

  const handleCommuneChange = useCallback((e, setFieldValue) => {
    const value = e.target.value;
    setSelectedCommune(value);
    setFieldValue("city", value);
  }, []);

  const navigate = useNavigate();

  return (
    <div
      className="container mt-4 mb-4 pt-3 col-10 col-md-8 col-lg-5 mx-auto background_blue"
      style={{ borderRadius: "8px" }}
    >
      <h1 className="text-center">Inscription</h1>
      <div className="d-flex flex-row mb-2 justify-content-around">
        <div>Vous avez déjà un compte ? </div>
        <div>
          <Link className="text-white" to="/login">
            Connectez-vous
          </Link>
        </div>
      </div>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          areaCode: "",
          city: "",
          phoneNumber: "",
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const {
              firstName,
              lastName,
              email,
              password,
              areaCode,
              city,
              phoneNumber,
            } = values;
            const response = await axios.post(`${API_URL}/register`, {
              firstName,
              lastName,
              email,
              password,
              areaCode,
              city,
              phoneNumber,
            });
            
            // Store the token and user data
            const token = response.data;
            const decode = jwtDecode(token);

            const userResponse = await axios.get(`${API_URL}/users`, {
              headers: {
                "x-access-token": token,
              },
            });

            // Store the token in cookies
            cookies.set("authToken", token, { path: "/" });

            // Dispatch the action to update context
            dispatch({
              type: "AUTH_SUCCESS",
              payload: { user: userResponse.data, token },
            });

            setSubmitting(false);
            navigate("/profile");
          } catch (error) {
            if (error.response) {
              if (error.response.status === 409) {
                setBackendErrors([error.response.data.message]);
              } else if (error.response.data.errors) {
                setBackendErrors(
                  error.response.data.errors.map((err) => err.msg)
                );
              }
            } else {
              setBackendErrors([
                "Une erreur s'est produite. Veuillez réessayer.",
              ]);
            }
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form className="pb-3 pt-3">
            <div className="mb-3">
              <label htmlFor="firstName" className="form-label">
                Prénom*
              </label>
              <Field
                type="text"
                className="form-control"
                id="firstName"
                name="firstName"
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="lastName" className="form-label">
                Nom*
              </label>
              <Field
                type="text"
                className="form-control"
                id="lastName"
                name="lastName"
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email*
              </label>
              <Field
                type="email"
                className="form-control"
                id="email"
                name="email"
                aria-describedby="emailHelp"
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Mot de passe*
              </label>
              <Field
                type="password"
                className="form-control"
                id="password"
                name="password"
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="phoneNumber" className="form-label">
                Numéro de téléphone*
              </label>
              <Field
                type="tel"
                className="form-control"
                id="phoneNumber"
                name="phoneNumber"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="areaCode" className="form-label">
                Code Postal*
              </label>
              <Field
                type="text"
                className="form-control"
                id="areaCode"
                name="areaCode"
                value={areaCode}
                onChange={(e) => handleAreaCodeChange(e, setFieldValue)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="city" className="form-label">
                Commune*
              </label>
              <Field
                component="select"
                className="form-control"
                id="city"
                value={selectedCommune}
                onChange={(e) => handleCommuneChange(e, setFieldValue)}
                required
              >
                <option value="">Sélectionnez une commune</option>
                {communes.map((commune, index) => (
                  <option key={index} value={commune.nomCommune}>
                    {commune.nomCommune}
                  </option>
                ))}
              </Field>
            </div>
            {backendErrors.length > 0 && (
              <div className="alert alert-danger">
                {backendErrors.map((error, index) => (
                  <div key={index}>{error}</div>
                ))}
              </div>
            )}
            <div className="text-end">
              <button
                type="submit"
                className="btn background_dark text-white"
                disabled={isSubmitting}
              >
                S'inscrire
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default SignUp;
