import React, { useState, useEffect } from "react";
import { FRONT_URL } from "../../utils/config";

const adminData = {
  home: { title1: "text", image1: "image", text1: "text", text2: "text" },
  aboutMe: { title1: "text", image1: "image", text1: "text", text2: "text" },
  service: { title1: "text", image1: "image", text1: "text", text2: "text" },
  contact: { title1: "text", image1: "image", text1: "text", text2: "text" },
};

function AdminDashboard(props) {
  const [selectedPage, setSelectedPage] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [inputType, setInputType] = useState("");

  const handlePageChange = (event) => {
    setSelectedPage(event.target.value);
    setSelectedSection("");
    setInputType("");
  };

  const handleSectionChange = (event) => {
    setSelectedSection(event.target.value);
    setInputType(adminData[selectedPage][event.target.value]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    fetch(`${FRONT_URL}/admin/update`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((error) => console.error("Error:", error));
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Choose Page:</label>
        <select value={selectedPage} onChange={handlePageChange}>
          <option value="">Select Page</option>
          {Object.keys(adminData).map((page) => (
            <option key={page} value={page}>
              {page}
            </option>
          ))}
        </select>
      </div>

      {selectedPage && (
        <div>
          <label>Choose Section:</label>
          <select value={selectedSection} onChange={handleSectionChange}>
            <option value="">Select Section</option>
            {Object.keys(adminData[selectedPage]).map((section) => (
              <option key={section} value={section}>
                {section}
              </option>
            ))}
          </select>
        </div>
      )}

      {selectedSection && (
        <div>
          <label>Modify {selectedSection}:</label>
          {inputType === "text" ? (
            <input type="text" name="content" />
          ) : (
            <input type="file" name="content" />
          )}
          <input type="hidden" name="page" value={selectedPage} />
          <input type="hidden" name="section" value={selectedSection} />
        </div>
      )}

      {selectedSection && <button type="submit">Submit</button>}
    </form>
  );
}

export default AdminDashboard;
