import React, { createContext, useReducer } from "react";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const initialState = {
  user: {},
  token: cookies.get("authToken") || null,
  isAuthenticated: !!cookies.get("authToken"),
};

const reducer = (state, action) => {
  switch (action.type) {
    case "AUTH_SUCCESS":
      cookies.set("authToken", action.payload.token, {
        path: "/",
        maxAge: 86400,
      });
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        isAuthenticated: true,
      };
    case "LOGOUT":
      cookies.remove("authToken", { path: "/" });
      return {
        ...state,
        user: null,
        token: null,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};

const Context = createContext({
  context: initialState,
  dispatch: () => {},
});

const ContextProvider = ({ children }) => {
  const [context, dispatch] = useReducer(reducer, initialState);
  return (
    <Context.Provider value={{ context, dispatch }}>
      {children}
    </Context.Provider>
  );
};

export { Context, ContextProvider };
