import React, { useContext, useEffect } from "react";
import { Context } from "../context";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import Logout from "../components/Logout";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../utils/config";

function Profile() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const handleLogout = () => {
    // Dispatch the LOGOUT action
    dispatch({ type: "LOGOUT" });
    navigate("/");
  };

  const { context, dispatch } = useContext(Context);

  useEffect(() => {
    if (!context.user.firstName) {
      // Load user data from API if not there
      const decode = jwtDecode(context.token);

      const fetchUserData = async () => {
        try {
          const userResponse = await axios.get(
            `${API_URL}/users/${decode.userId}`,
            {
              headers: { "x-access-token": context.token },
            }
          );

          dispatch({
            type: "AUTH_SUCCESS",
            payload: { user: userResponse.data, token: context.token },
          });
        } catch (error) {
          console.error(
            "Erreur lors de la récupération des données utilisateur:",
            error
          );
        }
      };
      fetchUserData();
    }
  }, [context.token, context.user.firstName, dispatch]);

  return (
    <div className="container mt-5">
      <div className="d-flex justify-content-between">
        {context.user.firstName ? (
          <h3>Bonjour {context.user.firstName}</h3>
        ) : (
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        )}
        <button
          type="button"
          className="btn background_blue p-1"
          onClick={handleLogout}
        >
          <nav className="nav-link active">Déconnexion </nav>
        </button>
      </div>
    </div>
  );
}

export default Profile;
