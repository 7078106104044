import React from "react";
import actu1 from "../assets/planning_nov1.png";
import actu2 from "../assets/planning_nov2.png";

function News(props) {
  return (
    <section className="w-100 mx-auto">
      <div className="text-center mt-5">
        <h2>Mes actualités</h2>
      </div>
      <div className="d-flex  mt-5 justify-content-center ">
        <div className="d-flex flex-wrap justify-content-around">
          <div className="m-2 d-flex justify-content-center">
            <img
              src={actu1}
              className="actu"
              alt="planning des activités d'octobre 2024, balade collective dimanche 13 et mantrailing samedi 26"
              style={{
                overflow: "hidden",
              }}
            />
          </div>
          <div className="m-2 d-flex justify-content-center">
            <img
              src={actu2}
              className="actu"
              alt="planning des activités d'octobre 2024, balade collective dimanche 13 et mantrailing samedi 26"
              style={{
                overflow: "hidden",
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default News;
