import React, { useEffect, useState } from "react";
import AdminHeader from "../../components/admin/AdminHeader";
import AdminMenu from "../../components/admin/AdminMenu";
import Price from "../../components/admin/Price";

function AdminPrice(props) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  // Écoute du changement de taille de l'écran pour mettre à jour l'état de mobile/non mobile
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      {isMobile ? (
        <div>
          <AdminHeader isMobile={isMobile} />
          <AdminMenu isMobile={isMobile} />
          <div className="row mt-5">
            <div className="mt-5 ms-2">
              <Price />
            </div>
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="fixed-top">
            <AdminHeader isMobile={isMobile} />
          </div>
          <div className="col-3 fixed-top" style={{ marginTop: "70px" }}>
            <AdminMenu isMobile={isMobile} />
          </div>
          <div className="row">
            <div className="col-3"></div>
            <div className="col-9 mt-5 pt-5">
              <div>
                {/* TODO : nom admin via props*/}
                <Price />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminPrice;
