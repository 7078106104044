import React, { useEffect } from "react";

function Legalmention(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container mt-3 col-10">
      <h1 className="text-center">
        Mentions légales <br /> Politique de confidentialité
      </h1>
      <section className=" mt-5">
        <p className="mt-4">
          Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004
          pour la confiance en l’économie numérique, il est précisé aux
          utilisateurs du site zooethos.fr l’identité des différents
          intervenants dans le cadre de sa réalisation et de son suivi.
        </p>
        <p className="mt-4">
          <strong>Edition du site</strong>{" "}
        </p>
        <p className="mt-2">Le site zooethos.fr est édité par Nina GIL, EI.</p>
        <p>
          Le siège social de Zooethos est situé 6 Place Saint Hilaire à Rouen et
          le numéro de SIRET est 90764854700012.
        </p>

        <p className="mt-4">
          <strong>Responsable de publication</strong>
        </p>
        <p className="mt-2">Nina GIL</p>
        <p className="mt-4">
          <strong>Hébergeur du site</strong>
        </p>
        <p className="mt-2">
          zooethos.fr est hébergé par la société O2Switch <br /> Adresse: Chemin
          des Pardiaux 63000 Clermont-Ferrand
        </p>
      </section>

      <section id="politique-de-confidentialité">
        <h2>Introduction</h2>

        <p>
          Chez <strong>Zooethos</strong>, nous nous engageons à protéger et à
          respecter votre vie privée. Cette politique de confidentialité
          explique comment nous collectons, utilisons, et partageons vos données
          personnelles lorsque vous utilisez notre site web{" "}
          <strong>zooethos.fr</strong> ou nos services.
        </p>
        <h2>Informations que nous collectons</h2>
        <p>
          Nous collectons différentes informations afin de vous fournir et
          d’améliorer nos services. Les types de données que nous pouvons
          collecter incluent :
        </p>
        <ul>
          <li>
            <strong>Informations personnelles</strong> : Lorsque vous remplissez
            un formulaire, ou utilisez nos services, nous pouvons collecter des
            informations telles que votre nom, adresse e-mail, adresse postale,
            numéro de téléphone, et autres informations de contact.
          </li>
          <li>
            <strong>Informations de navigation</strong> : Nous collectons des
            informations relatives à la manière dont vous accédez et utilisez
            notre site, y compris votre adresse IP, le type de navigateur, les
            pages consultées, la durée de visite, et les liens sur lesquels vous
            cliquez.
          </li>
          {/* <li>
            <strong>Cookies et technologies similaires</strong> : Nous utilisons
            des cookies pour améliorer votre expérience sur notre site, suivre
            l’utilisation, et personnaliser le contenu que nous vous proposons.
            Vous pouvez consulter notre{" "}
            <a href="[Lien vers la Politique de Cookies]">
              Politique de Cookies
            </a>{" "}
            pour plus d'informations à ce sujet.
          </li> */}
        </ul>

        <h2>Comment utilisons-nous vos informations ?</h2>
        <p>
          Nous utilisons les informations que nous collectons à des fins de
          gestion de nos services, pour répondre à vos demandes.
        </p>

        <h2>Partage de vos informations</h2>
        <p>
          Nous ne vendons, louons ou échangeons pas vos informations
          personnelles. Cependant, nous pouvons partager vos données avec :
        </p>
        <ul>
          <li>
            <strong>Prestataires de services</strong> : Nous faisons appel à des
            prestataires de services tiers pour effectuer des tâches en notre
            nom, comme l'hébergement, le traitement des paiements, ou l'analyse
            de données. Ces prestataires sont tenus de protéger vos informations
            et de les utiliser uniquement pour les services qu'ils fournissent.
          </li>
          <li>
            <strong>Exigences légales</strong> : Nous pouvons divulguer vos
            informations si la loi l’exige ou si nous croyons de bonne foi que
            cela est nécessaire pour se conformer à une procédure judiciaire,
            protéger nos droits, ou assurer la sécurité de nos utilisateurs.
          </li>
        </ul>

        <h2>Protection de vos informations</h2>
        <p>
          Nous prenons des mesures de sécurité appropriées pour protéger vos
          données personnelles contre tout accès non autorisé, modification,
          divulgation ou destruction. Cela inclut des mesures techniques et
          organisationnelles telles que le cryptage des données sensibles.
        </p>

        <h2>Vos droits</h2>
        <p>
          En vertu du Règlement Général sur la Protection des Données (RGPD) et
          d’autres législations similaires, vous disposez de plusieurs droits
          concernant vos données personnelles, notamment :
        </p>
        <ul>
          <li>
            <strong>Droit d'accès</strong> : Vous pouvez demander à tout moment
            une copie des données personnelles que nous détenons à votre sujet.
          </li>
          <li>
            <strong>Droit de rectification</strong> : Vous pouvez demander la
            correction des données inexactes ou incomplètes.
          </li>
          <li>
            <strong>Droit de suppression</strong> : Vous avez le droit de
            demander la suppression de vos informations personnelles, sous
            réserve de certaines exceptions légales.
          </li>
          <li>
            <strong>Droit à la portabilité des données</strong> : Vous pouvez
            demander à recevoir vos données dans un format lisible par machine
            ou à les faire transférer à un autre responsable de traitement.
          </li>
          <li>
            <strong>Droit de retrait du consentement</strong> : Si nous traitons
            vos données personnelles sur la base de votre consentement, vous
            pouvez retirer ce consentement à tout moment.
          </li>
        </ul>
        <p>
          Pour exercer ces droits, veuillez nous contacter à l’adresse suivante
          : <strong>contactzooethos@gmail.com</strong>.
        </p>

        <h2>Durée de conservation des données</h2>
        <p>
          Nous conservons vos données personnelles uniquement aussi longtemps
          que nécessaire pour les finalités décrites dans cette politique ou
          pour nous conformer à nos obligations légales. Une fois ces délais
          écoulés, vos données seront supprimées ou anonymisées.
        </p>

        <h2>Modifications de la politique de confidentialité</h2>
        <p>
          Nous nous réservons le droit de modifier cette politique de
          confidentialité à tout moment. Toute modification sera publiée sur
          cette page et, le cas échéant, vous serez informé par e-mail. Veuillez
          consulter régulièrement cette page pour rester informé de tout
          changement.
        </p>

        <h2>Contact</h2>
        <p>
          Si vous avez des questions concernant cette politique de
          confidentialité ou si vous souhaitez exercer vos droits, veuillez nous
          contacter à :
        </p>
        <p>
          <strong>contactzooethos@gmail.com</strong> <br />
          <strong>07 77 17 39 88</strong>
        </p>
      </section>
    </div>
  );
}

export default Legalmention;
