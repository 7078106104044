import React, { useEffect } from "react";
import balade from "../assets/head_balade5.jpg";
import Prestation from "../components/Prestation";
import Footer from "../components/Footer";

function BaladeCanine(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const service = "BALADE CANINE";

  const id = 2;

  return (
    <div>
      <div className="container">
        <div className="col-12 mt-3 text-center">
          <h1 className="mb-5 fw-bold">BALADE CANINE</h1>
          <img
            src={balade}
            alt="Nina GIL et deux femmes en forêt promenant des chiens"
            className="headIllustration  img-fluid rounded-border "
          />
        </div>
        <div>
          <Prestation service={service} id={id} />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default BaladeCanine;
