import React, { useContext, useEffect, useState } from "react";
import AdminMenu from "../../components/admin/AdminMenu";
import AdminHeader from "../../components/admin/AdminHeader";
import UploadFile from "../../components/UploadFile";
import AdminInformation from "../../components/admin/AdminInformation";
import { Context } from "../../context";

function Admin(props) {
  const { context } = useContext(Context);

  return (
    <div className="container">
      {props.isMobile ? (
        <div>
          <AdminHeader isMobile={props.isMobile} />
          <AdminMenu isMobile={props.isMobile} />

          <UploadFile isMobile={props.isMobile} />
          <AdminInformation />
        </div>
      ) : (
        <div>
          <div className="fixed-top">
            <AdminHeader isMobile={props.isMobile} />
          </div>
          <div className="col-3 fixed-top" style={{ marginTop: "70px" }}>
            <AdminMenu isMobile={props.isMobile} />
          </div>
          <div className="row">
            <div className="col-3"></div>
            <div className="col-9 mt-5 pt-5">
              <div>
                {/* TODO : nom admin via props*/}
                <strong className="h1">
                  Bonjour {context.user ? context.user.firstName : ""}
                </strong>
              </div>
              <UploadFile />
              <AdminInformation />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Admin;
