import "./App.css";
import SignUp from "./pages/SignUp";
import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Test from "./pages/Test";
import AboutMe from "./pages/AboutMe";
import Blog from "./components/Blog";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Header from "./components/Header";
import Profile from "./pages/Profile";
import Login from "./pages/Login";
import Admin from "./pages/admin/Admin";
import BaladeCanine from "./pages/BaladeCanine";
import EducationCanine from "./pages/EducationCanine";
import GardeDomicile from "./pages/GardeDomicile";
import AdminBlog from "./pages/admin/AdminBlog";
import AdminPrice from "./pages/admin/AdminPrice";
import AdminFormation from "./pages/admin/AdminFormation";
import AdminPage from "./pages/admin/AdminPage";
import balade from "./assets/balade_canine.jpg";
import educ from "./assets/education_canine.jpg";
import garde from "./assets/garde_domicile.jpg";
import ForgotPassword from "./pages/ForgotPassword";
import NotFound from "./pages/NotFound";
import ProtectedRoute from "./components/ProtectedRoute";
import AdminRoute from "./components/AdminRoute";
import Legalmention from "./pages/legalMention/LegalMention";
import ScrollToTop from "react-scroll-to-top";

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  // Écoute du changement de taille de l'écran pour mettre à jour l'état de mobile/non mobile
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="App ">
      <ScrollToTop smooth />
      {location.pathname.startsWith("/admin") ? (
        <div>
          <Routes>
            <Route
              path="/admin/home"
              element={
                <AdminRoute>
                  <Admin isMobile={isMobile} />
                </AdminRoute>
              }
            />
            <Route
              path="/admin/blog"
              element={
                <AdminRoute>
                  <AdminBlog />
                </AdminRoute>
              }
            />
            <Route
              path="/admin/price"
              element={
                <AdminRoute>
                  <AdminPrice />
                </AdminRoute>
              }
            />
            <Route
              path="/admin/formation"
              element={
                <AdminRoute>
                  <AdminFormation />
                </AdminRoute>
              }
            />
            <Route
              path="/admin/page"
              element={
                <AdminRoute>
                  <AdminPage />
                </AdminRoute>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      ) : (
        <div className="d-block w-100">
          <Header isMobile={isMobile} />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/test" element={<Test />} />
            <Route path="/about-me" element={<AboutMe />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/contact" element={<Contact isMobile={isMobile} />} />
            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
            <Route path="/login" element={<Login />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/baladecanine" element={<BaladeCanine />} />
            <Route path="/educationcanine" element={<EducationCanine />} />
            <Route
              path="/gardedomicile"
              element={<GardeDomicile />}
            />
            <Route path="/mentions-légales" element={<Legalmention />} />

            <Route path="*" element={<NotFound />} />
            <Route path="/404" element={<NotFound />} />
          </Routes>
        </div>
      )}
    </div>
  );
}

export default App;
