import React from "react";
import Service from "../components/Service";
import ComponentTest from "../components/ComponentTest";
import SignUp from "./SignUp";

function Test(props) {
  return (
    <div>
      <ComponentTest />
      <Service />
      <SignUp />
    </div>
  );
}

export default Test;
