import React, { useEffect } from "react";
import histoire from "../assets/mon-histoire.jpg";
import approche from "../assets/mon-approche.jpg";
import formation from "../assets/formation.jpg";
import Footer from "../components/Footer";
import Partenaires from "../components/Partenaires";

function AboutMe(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="container p-0 mt-4 w-75 mx-auto">
        <section className="d-flex flex-wrap ">
          <div className="col-12 col-lg-4 d-flex justify-content-center align-items-center">
            {/* à laisser pour la mise en page*/}
          </div>
          <section className="col-12 col-lg-8 d-flex justify-content-center align-items-center">
            <h1>Mon histoire</h1>
          </section>

          <div className="col-12 col-lg-4 mt-2 d-flex justify-content-center align-items-center">
            <img
              className="illustration"
              src={histoire}
              alt="Nina GIL enfant avec un bichon"
            />
          </div>

          <div className="col-12 col-lg-8 mt-2 justify-content-center align-items-center p-2 ">
            <div className="p-0 m-0 mb-3">
              Nina, née à la campagne dans le sud de la France, j'ai grandi au
              grand air entourée d'animaux. <br /> Fille unique, les chien·nes,
              chat·tes, poules et chèvres étaient mes meilleur·es ami·es !
            </div>
            <div className="p-0 m-0 mb-3">
              C'est donc très tôt que j'ai commencé à les observer, à interagir
              avec elles et eux, et à essayer de les comprendre. Je suis devenue
              bénévole dans la protection animale et famille d'accueil alors que
              je vivais encore chez mes parents.
            </div>
            <div className="p-0 m-0 mb-3">
              Après des années de bénévolat, j'ai décidé de faire de ma passion
              mon métier ! J'ai travaillé en tant que soigneuse dans différents
              refuges, puis j'ai repris mes études en 2021 pour passer le
              diplôme d'état d'éducatrice canine.
            </div>{" "}
            <span className="fw-bold fs-4">La suite, c'est Zooethos !</span>
          </div>

          <div
            className="w-100 mt-4 background_blue"
            style={{ height: "1px" }}
          ></div>

          <div className="col-12 col-lg-8 mt-4 d-flex justify-content-center align-items-center">
            <h2>Mon approche</h2>
          </div>
          <div className="col-12 col-lg-4 mt-4 d-flex justify-content-center align-items-center"></div>
          <div className="col-12 col-lg-4 col-lg-4 order-2 mt-2 d-flex justify-content-center align-items-center">
            <img
              className="illustration"
              src={approche}
              alt="Nina GIL tenant un chien en longue dans la forêt"
            />
          </div>

          <div className="col-12 col-lg-8 mt-2 justify-content-center align-items-center p-3 ">
            <div className="p-0 m-0 mb-3">
              Les deux piliers de tous mes services sont la bienveillance et le
              savoir éthologique.{" "}
            </div>
            <div className="p-0 m-0 mb-3">
              La bienveillance me semble être la base de toute relation avec les
              individus que j'accompagne. Elle me permet, en respectant
              l'individualité et les besoins de chacun·e, de tisser des liens,
              de comprendre, et de pouvoir m'adapter à tous·tes.. Cette
              bienveillance va bien évidement de pair avec des méthodes
              respectueuses, qui exclue toute utilisation de méthode et d'outils
              coercitifs.{" "}
            </div>
            <div className="p-0 m-0 mb-3">
              Le savoir éthologique est très important à mes yeux, bien sûr
              l'expérience me permet d'affiner mon regard, mais des
              connaissances à jour me permettent de toujours perfectionner mon
              travail. Que ce soit par des formations régulières, des échanges
              avec d'autres professionnel·les du domaine, ou des lectures
              scientifiques, j'ai une grande soif de connaissances !
            </div>
          </div>
        </section>
        <section className="col-12 col-md-12 mt-5 p-4 shadow justify-content-around align-items-center rounded-border ">
          <div className="row">
            <div className="col-12 col-md-6">
              <h2 className="text-center mb-5 marine_zooethos">
                Ce que je propose
              </h2>
              <div className="row marine_zooethos">
                <div className="col-1">
                  <strong>✓</strong>
                </div>
                <div className="col-9 ">
                  <p>
                    une approche respectueuse et bienveillante des animaux et
                    des humain·es
                  </p>
                </div>
              </div>
              <div className="row marine_zooethos">
                <div className="col-1">
                  <strong>✓</strong>
                </div>
                <div className="col-9 ">
                  <p>
                    des connaissances basées sur des données scientifiques à
                    jour
                  </p>
                </div>
              </div>
              <div className="row marine_zooethos">
                <div className="col-1">
                  <strong>✓</strong>
                </div>
                <div className="col-9 ">
                  <p>
                    un accompagnement personnalisé adapté aux besoins de
                    chacun·e
                  </p>
                </div>
              </div>
              <div className="row marine_zooethos">
                <div className="col-1">
                  <strong>✓</strong>
                </div>
                <div className="col-9 ">
                  <p>
                    des activités partagées pour les duos humain·es - chien·nes
                  </p>
                </div>
              </div>
              <div className="row marine_zooethos">
                <div className="col-1">
                  <strong>✓</strong>
                </div>
                <div className="col-9 ">
                  <p>
                    la transmission de compétences pour toujours mieux
                    comprendre votre animal
                  </p>
                </div>
              </div>
              <div className="row marine_zooethos">
                <div className="col-1">
                  <strong>✓</strong>
                </div>
                <div className="col-9 ">
                  <p>
                    une collaboration avec d'autres acteur·ices du monde animal
                    comme des vétérinaires, des associations, des
                    toiletteur·ses; pour une prise en charge globale
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 ">
              <h2 className="text-center mb-5 alert-color">
                {" "}
                Ce que je ne propose pas
              </h2>
              <div className="row alert-color">
                <div className="col-1">
                  <strong>X</strong>
                </div>
                <div className="col-9 ">
                  <p>
                    l'utilisation de nourriture, de jouets, ou du clicker dans
                    le travail d'éducation ou de ré-éducation
                  </p>
                </div>
              </div>
              <div className="row alert-color">
                <div className="col-1">
                  <strong>X</strong>
                </div>
                <div className="col-9 ">
                  <p>l'obéissance et le surconditionnement </p>
                </div>
              </div>
              <div className="row alert-color">
                <div className="col-1">
                  <strong>X</strong>
                </div>
                <div className="col-9 ">
                  <p>
                    les activités canines de type agility, dogdancing, treiball,
                    mordant, canicross, frisbee, flyball{" "}
                  </p>
                </div>
              </div>
              <div className="row alert-color">
                <div className="col-1">
                  <strong>X</strong>
                </div>
                <div className="col-9 ">
                  <p>
                    les outils coercitifs comme les colliers étrangleurs,
                    torquatus..., ainsi que toute autre violence physique ou
                    phychologique
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="text-center m-5">
            <h2>Mes formations</h2>
          </div>

          <div className="col-12 mt-3 p-2 rounded-border d-flex flex-wrap align-items-center textured">
            <div className="col-12 text-center mt-3">
              <h3>Formations diplômantes / certifiantes</h3>
            </div>
            <div className="col-12 col-lg-8 p-0">
              <div className="col-12 d-flex justify-content-around align-items-center p-3 ">
                <div className="col-3 circle rounded-circle shadow background_dark text-light">
                  2024
                </div>
                <div className="col-9 ps-2">
                  <div>
                    <span className="fw-bold">
                      Premiers secours canin & félin{" "}
                    </span>{" "}
                    Humanimal
                  </div>
                  <div>
                    <span className="fw-bold">Mentorat </span> Sasha GOLDMAN
                  </div>
                  <div>
                    {" "}
                    <span className="fw-bold">
                      Entretien motivationnel
                    </span>{" "}
                    Game of paws
                  </div>
                </div>
              </div>

              <div className="col-12 d-flex justify-content-around align-items-center p-3 ">
                <div className="col-3 circle rounded-circle shadow background_dark text-light">
                  2023
                </div>
                <div className="col-9 ps-2">
                  <div>
                    <span className="fw-bold">
                      Attestation d'Aptitude chiens de 1ère et 2ème catégories{" "}
                    </span>
                    Romy SAUVAGEOT
                  </div>
                </div>
              </div>
              <div className="col-12 d-flex justify-content-around align-items-center p-3 ">
                <div className="col-3 circle rounded-circle shadow background_dark text-light">
                  2021
                </div>
                <div className="col-9 ps-2">
                  <div>
                    {" "}
                    <span className="fw-bold">
                      Brevet Professionnel éducateur canin
                    </span>{" "}
                    CFPPA de Pézénas
                  </div>
                </div>
              </div>
              <div className="col-12 d-flex justify-content-around align-items-center p-3 ">
                <div className="col-3 circle rounded-circle shadow background_dark text-light">
                  2020
                </div>
                <div className="col-9 ps-2">
                  <div style={{ overflowWrap: "break-word" }}>
                    <span className="fw-bold">
                      Optimiser l'accompagnement du chien de refuge
                    </span>{" "}
                    Muzo+
                  </div>
                </div>
              </div>
              <div className="col-12 d-flex justify-content-around align-items-center p-3 ">
                <div className="col-3 circle rounded-circle shadow background_dark text-light">
                  2014
                </div>
                <div className="col-9 ps-2">
                  <div>
                    <span className="fw-bold">ACACED chien - chat - NAC </span>
                    CFPPA de Pézénas
                  </div>
                </div>
              </div>
            </div>
            <div className="col d-none d-lg-block">
              <img
                src={formation}
                className="illustration"
                alt="illustration de diplôme"
              />
            </div>
          </div>

          <div className="col-12 mt-3 p-2 rounded-border d-flex flex-wrap align-items-center background_dark">
            <div className="col-12 text-center mt-3">
              <h3>Stages</h3>
            </div>

            <div className="col-12 d-flex justify-content-around align-items-center p-3 ">
              <div className="col-3 circle rounded-circle shadow textured ">
                2023
              </div>
              <div className="col-9 ps-2">
                <div>
                  <span className="fw-bold">Construire ses séances </span> Sasha
                  GOLDMAN
                </div>
                <div>
                  <span className="fw-bold">Gestion des chiens en groupe </span>{" "}
                  Laura SGARD / Sasha GOLDMAN
                </div>
                <div>
                  <span className="fw-bold">Enrichir sa culture canine </span>{" "}
                  Sasha GOLDMAN
                </div>
                <div>
                  <span className="fw-bold">Soins coopératifs </span> Iris
                  CASTAING
                </div>
              </div>
            </div>
            <div className="col-12 d-flex justify-content-around align-items-center p-3 ">
              <div className="col-3 circle rounded-circle shadow textured ">
                2022
              </div>
              <div className="col-9 ps-2">
                <div>
                  <span className="fw-bold">Pratique sur le terrain </span>{" "}
                  Valérie GONCALVES / Laura SGARD
                </div>
                <div>
                  <span className="fw-bold">A l'écoute du chien </span> Nadine
                  CHASTANG / Sasha GOLDMAN
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 mt-3 p-2 rounded-border d-flex flex-wrap align-items-center textured">
            <div className="col-12 text-center mt-3">
              <h3>Autres formations</h3>
            </div>

            <div className="col-12 d-flex justify-content-around align-items-center p-3 ">
              <div className="col-3 circle rounded-circle shadow background_dark text-light">
                2022
              </div>
              <div className="col-9 ps-2">
                <div>
                  <span className="fw-bold">
                    10 activités pour stimuler mon chien
                  </span>{" "}
                  Canidélite
                  <div>
                    <span className="fw-bold">Apprentissage chien (Niv1)</span>{" "}
                    Canidélite
                  </div>
                  <div>
                    <span className="fw-bold">Cohabitation chien et chat</span>{" "}
                    Canidélite
                  </div>
                  <div>
                    <span className="fw-bold">
                      Mon chiot, éducation et comportement
                    </span>{" "}
                    Canidélite
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 d-flex justify-content-around align-items-center p-3 ">
              <div className="col-3 circle rounded-circle shadow background_dark text-light">
                2020
              </div>
              <div className="col-9 ps-2">
                <div>
                  <span className="fw-bold">Chatvoir 1 & 2 & 3 </span>{" "}
                  Educhateur
                </div>
              </div>
            </div>
          </div>

          <Partenaires />
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default AboutMe;
