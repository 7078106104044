import { NavLink } from "react-router-dom";

function AdminMenu(props) {
  return (
    <div className="">
      {props.isMobile ? (
        <nav className="navbar navbar-expand-lg shadow fixed-bottom bg-secondary-subtle ">
          <div className="container-fluid ">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 d-flex flex-row justify-content-around w-100 ">
              <li className="nav-item ">
                <NavLink
                  className="nav-link active"
                  aria-current="page"
                  to="/admin/home"
                >
                  Accueil
                </NavLink>
              </li>
              <li className="nav-item ">
                <NavLink
                  className="nav-link active "
                  aria-current="page"
                  to="/admin/blog"
                >
                  Blog
                </NavLink>
              </li>
              <li className="nav-item ">
                <NavLink
                  className="nav-link active "
                  aria-current="page"
                  to="/admin/price"
                >
                  Tarifs
                </NavLink>
              </li>
              <li className="nav-item ">
                <NavLink
                  className="nav-link active "
                  aria-current="page"
                  to="/admin/formation"
                >
                  Formations
                </NavLink>
              </li>
              <li className="nav-item ">
                <NavLink
                  className="nav-link active"
                  aria-current="page"
                  to="/admin/page"
                >
                  Pages
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
      ) : (
        <nav className="navbar navbar-vertical shadow vh-100 navbar-expand-md bg-secondary-subtle pb-5">
          <ul className="navbar-nav flex-column">
            <div className="ms-2 fs-3">
              <strong>Tableau de bord</strong>
            </div>
            <li className="nav-item pt-4">
              <NavLink
                className="nav-link active fs-4"
                aria-current="page"
                to="/admin/home"
              >
                Accueil
              </NavLink>
            </li>
            <li className="nav-item pt-2">
              <NavLink
                className="nav-link active fs-4"
                aria-current="page"
                to="/admin/blog"
              >
                Blog
              </NavLink>
            </li>
            <li className="nav-item pt-2">
              <NavLink
                className="nav-link active fs-4"
                aria-current="page"
                to="/admin/price"
              >
                Tarifs
              </NavLink>
            </li>
            <li className="nav-item pt-2">
              <NavLink
                className="nav-link active fs-4"
                aria-current="page"
                to="/admin/formation"
              >
                Formations
              </NavLink>
            </li>
            <li className="nav-item pt-2 pb-5">
              <NavLink
                className="nav-link active fs-4"
                aria-current="page"
                to="/admin/page"
              >
                Pages
              </NavLink>
            </li>
          </ul>
        </nav>
      )}
    </div>
  );
}

export default AdminMenu;
