import React from "react";
import chat1 from "../assets/chat1.jpg";
import dog2 from "../assets/dog2.jpg";
import lapin from "../assets/lapin-carroussel.jpg";
import cat2 from "../assets/chat2.jpg";
import dog5 from "../assets/dog5.jpg";
import dog6 from "../assets/dog6.jpg";
import bird from "../assets/oiseau-carroussel.jpg";
import jo from "../assets/jo-carroussel.jpg";

function Mozaic(props) {
  return (
    <div className="">
      <div className="row mt-3 ">
        <div className="col-4">
          <img src={chat1} alt="chat noir et blanc sur un arbre à chat" className="img-fluid rounded-border " />
        </div>
        <div className="col-4">
          <img src={dog6} alt="corgy dans la neige" className="img-fluid rounded-border " />
        </div>
        <div className="col-4">
          <img src={jo} alt="octodon dans une main" className="img-fluid rounded-border " />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-6">
          <img src={lapin} alt="lapin sur un tapis devant un canapé" className="img-fluid rounded-border " />
        </div>
        <div className="col-6">
          <img src={dog5} alt="chien qui marche dans l'herbe" className="img-fluid rounded-border " />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-4">
          <img src={bird} alt="perroquet sur un perchoir" className="img-fluid rounded-border " />
        </div>
        <div className="col-4">
          <img src={dog2} alt="chien qui courre en forêt" className="img-fluid rounded-border " />
        </div>
        <div className="col-4">
          <img src={cat2} alt="chat Sacré de Birmanie" className="img-fluid rounded-border " />
        </div>
      </div>
    </div>
  );
}

export default Mozaic;
