import React from "react";

function Price(props) {
  return (
    <div>
      <strong className="h1">Tarifs</strong>

      <div className="mt-5 align-items-center">
        <div className="col-12 rounded-border background_blue col-lg-10 justify-content-center p-3">
          <div className="mt-2 mb-2 ">
            <div className="ms-1 me-1 row ">
              <div className="col-12">
                <select
                  className="form-select mb-2"
                  aria-label="Default select example"
                >
                  <option selected>Service</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
            </div>
            <div className="ms-1 me-1 row ">
              <div className="col-12">
                <select
                  className="form-select mb-2"
                  aria-label="Default select example"
                >
                  <option selected>Prestation</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
            </div>
            <div className="ms-1 me-1 row justify-content-around">
              <div className="col-6 col-md-8">
                <div className=" input-group mb-2 ">
                  <input
                    type="text"
                    className="form-control"
                    aria-label="Dollar amount (with dot and two decimal places)"
                  />
                  <span className="input-group-text">€</span>
                </div>
              </div>
              <div className="col-6 col-md-4 text-end">
                <button type="button" className="btn btn-light">
                  Changer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Price;
