import React, { useContext } from "react";
import logo from "../../assets/Icono_-_couleurs_-_fond_bleu_clair.png";
import { NavLink, useNavigate } from "react-router-dom";
import { Context } from "../../context";

function AdminHeader(props) {
  const { context, dispatch } = useContext(Context);
  const navigate = useNavigate();

  const handleLogout = () => {
    // Dispatch the LOGOUT action
    dispatch({ type: "LOGOUT" });
    navigate("/");
  };

  return (
    <div>
      <nav className="p-1 navbar background_blue fixed-top justify-content-between ps-3 pe-3">
        {props.isMobile ? (
          <div>
            <strong className="ps-2 h3">
              Bonjour {context.user ? context.user.firstName : ""}
            </strong>
          </div>
        ) : (
          <div>
            <img src={logo} alt="logo Zooethos" width="50" />
            <span className="navbar-brand mb-0 h1">Zooethos</span>
          </div>
        )}

        <div className="d-grid gap-2 d-md-flex justify-content-md-end me-2 me-lg-5">
          <button type="button" className="btn btn-light p-1">
            <NavLink className="nav-link active" to="/">
              Revenir au site
            </NavLink>
          </button>
          <button
            type="button"
            className="btn btn-light p-1"
            onClick={handleLogout}
          >
            <nav className="nav-link active">Déconnexion </nav>
          </button>
        </div>
      </nav>
    </div>
  );
}

export default AdminHeader;
