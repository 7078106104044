import React from "react";

function Chalandise(props) {
  return (
    <section>
      <div className="text-center mt-5 mb-5">
        <h2>Ma zone d'intervention</h2>
      </div>
      <div className="d-flex justify-content-center">
        <iframe
          src="https://www.google.com/maps/d/embed?mid=1AvEwEsMdVO4EyA2nTks1h_wvT9h8sJM&ehbc=2E312F&noprof=1"
          width="840em"
          height="480em"
          loading="lazy"
        ></iframe>
      </div>
    </section>
  );
}

export default Chalandise;
