import React from "react";
import Dropzone from "./Dropzone";

function UploadFile(props) {
  return (
    <div>
      <div className="pt-5 pt-md-0">
        <div className="mt-5 align-items-center">
          <h3 className="mt-4">Partage client</h3>
          <div className="col-12 rounded-border background_blue col-lg-10 justify-content-center ">
            <div className="mx-auto">
              <div className="content-center">
                <Dropzone />
                <div className="ms-1 me-1 row ">
                  <div className="col-12">
                    <select
                      className="form-select mb-2"
                      aria-label="Default select example"
                      defaultValue={0}
                    >
                      <option value="0">Envoyer à </option>
                      <option value="1">Formation diplômante</option>
                      <option value="2">Stage</option>
                      <option value="3">Autre formation</option>
                    </select>
                  </div>
                </div>
                <div className="ms-1 me-1 row">
                  <div className="col-12 mb-2 text-end">
                    <button type="button" className=" btn btn-light p-1 ">
                      Envoyer
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UploadFile;
