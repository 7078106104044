import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

function Dropzone(props) {
  const [files, setFiles] = useState([]);
  const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone({
      onDrop: (acceptedFiles) => {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      },
      accept: {
        "image/png": [".png"],
        "image/jpeg": [".jpeg"],
      },
    });

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          alt=""
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <div className="ms-1 me-1 pt-3 row align-items-center">
      <div className="col-12">
        <div
          {...getRootProps()}
          className={`d-flex flex-wrap mx-auto p-4 border rounded-border ${
            isDragActive ? "border-primary" : "border-secondary"
          }`}
          style={{
            background: isDragActive ? "#e0f7fa" : "#f7f7f7",
            cursor: "pointer",
          }}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p className="text-center w-100">Déposez les fichiers ici...</p>
          ) : (
            <p className="text-center w-100">
              Glisser & déposer pour charger le fichier ou cliquez pour
              sélectionner un fichier
            </p>
          )}
        </div>
        <aside style={thumbsContainer}>{thumbs}</aside>
      </div>
    </div>
  );
}

export default Dropzone;
