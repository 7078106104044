import React from "react";

function NotFound(props) {
  return (
    <div className="container mt-5 mx-auto">
      <h1>Cette page n'existe pas.</h1>
    </div>
  );
}

export default NotFound;
