import React, { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Context } from "../context";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { isTokenExpired } from "../utils/authUtils";
import { API_URL } from "../utils/config";

const AdminRoute = ({ children }) => {
  const { context, dispatch } = useContext(Context);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Load user data from API if not there

    if (!context.token) {
      setLoading(false);
      return;
    }
    if (isTokenExpired(context.token)) {
      dispatch({ type: "LOGOUT" });
      setLoading(false);
      return;
    }

    if (!context.user.userId) {
      const fetchUserData = async () => {
        try {
          const decode = jwtDecode(context.token);

          const userResponse = await axios.get(
            `${API_URL}/users/${decode.userId}`,
            {
              headers: { "x-access-token": context.token },
            }
          );

          dispatch({
            type: "AUTH_SUCCESS",
            payload: { user: userResponse.data, token: context.token },
          });
        } catch (error) {
          setError(error);
          console.error(
            "Erreur lors de la récupération des données utilisateur:",
            error
          );
        } finally {
          setLoading(false);
        }
      };

      fetchUserData();
    }
  }, [context.token, dispatch, context.user.userId]);

  if (loading)
    return (
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    );
  if (error) return <div>Error: {error.message}</div>;

  return context.isAuthenticated && context.user.isAdmin ? (
    children
  ) : (
    // Redirect based on whether the token is expired or not
    <Navigate
      to={
        !context.token
          ? "/404"
          : isTokenExpired(context.token)
          ? "/login"
          : "/404"
      }
    />
  );
};

export default AdminRoute;
