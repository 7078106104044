import React, { useEffect } from "react";

import Main from "../components/Main";
import Footer from "../components/Footer";

function Home(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Main />
      <Footer />
    </div>
  );
}

export default Home;
