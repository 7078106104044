import React, { useEffect } from "react";
import Prestation from "../components/PrestationGarde";
import garde from "../assets/head_garde.jpg";
import Footer from "../components/Footer";

function GardeDomicile(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const service = "GARDE A DOMICILE";

  const id = 3;

  return (
    <div>
      <div className="container">
        <div className="col-12 mt-3 text-center">
        <h1 className="mb-5 fw-bold">GARDE A DOMICILE</h1>
          <img
            src={garde}
            alt="balade"
            className="img-fluid rounded-border headIllustration"
          />
        </div>
        <div>
          <Prestation service={service} id={id} />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default GardeDomicile;
