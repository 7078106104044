import React from "react";
import nina from "../assets/nina-gaia.jpg";
import { useLocation } from "react-router-dom";
import Service from "./Service";
import Chalandise from "./Chalandise";
import News from "./News";

function Main(props) {
  const location = useLocation();

  return location.pathname === "/" ? (
    <div className="container p-0 mt-4 w-75 mx-auto">
      <section className="d-flex flex-wrap">
        <div className="col col-md-4 d-flex justify-content-center align-items-center d-none d-md-flex"></div>
        <div className="col col-md-8 d-flex justify-content-center align-items-center">
          <h1 className="text-center">
            Zooethos <br /> Au service des animaux et des humains
          </h1>
        </div>
        <div className="col col-lg-4 mt-2 d-flex justify-content-center align-items-center ">
          <img
            className="illustration"
            src={nina}
            alt="Nina GIL ayant dans les bras un cocker"
          />
        </div>

        <div className="col col-lg-8 mt-2 justify-content-center align-items-center p-3">
          <div className="p-0 m-0 mb-3">
            Fondée sur des valeurs de respect et de compréhension du monde
            animal, Zooethos est à vos côtés dans votre vie quotidienne avec vos
            compagnon·nes à poils, à plumes, ou à écailles.
          </div>
          <div className="p-0 m-0">
            Pour vous accompagner vers une vie plus harmonieuse ou pour prendre
            le relais quand vous n'êtes pas disponible, mes services sont basés
            sur des connaissances scientifiques à jour, une écoute
            bienveillante, et une adaptation à vos besoins.
          </div>
        </div>
      </section>

      <Service />
      <Chalandise />
      <News />
    </div>
  ) : (
    <div> {location.pathname} </div>
  );
}

export default Main;
