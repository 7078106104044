import React, { useEffect } from "react";
import educ from "../assets/head_educ.jpg";
import Prestation from "../components/Prestation";
import Footer from "../components/Footer";

function EducationCanine(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const service = "EDUCATION CANINE";

  const id = 1;

  return (
    <div>
      <div className="container">
        <div className="col-12 mt-3 text-center">
          <h1 className="mb-5 fw-bold">EDUCATION CANINE</h1>
          
          <img
            src={educ}
            alt="balade"
            className="img-fluid rounded-border headIllustration"
          />
        </div>
        <div>
          <Prestation service={service} id={id} />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default EducationCanine;
