import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { Context } from "../context";

const ProtectedRoute = ({ children }) => {
  const { context } = useContext(Context);
  return context.isAuthenticated ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;
