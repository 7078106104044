import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import { API_URL } from "../utils/config";

function ForgotPassword(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [backendErrors, setBackendErrors] = useState(null);

  const navigate = useNavigate();

  return (
    <div
      className="container mt-5 pt-3 col-10 col-md-8 col-lg-5 mx-auto background_blue"
      style={{ borderRadius: "8px" }}
    >
      <h1 className="text-center">Réinitialiser votre mot de passe</h1>
      <div className="d-flex flex-row mb-2 justify-content-around"></div>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          areaCode: "",
          city: "",
          phoneNumber: "",
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const {
              firstName,
              lastName,
              email,
              password,
              areaCode,
              city,
              phoneNumber,
            } = values;
            await axios.post(`${API_URL}/register`, {
              firstName,
              lastName,
              email,
              password,
              areaCode,
              city,
              phoneNumber,
            });
            setSubmitting(false);
            navigate("/");
          } catch (error) {
            if (error.response && error.response.data.errors) {
              setBackendErrors(error.response.data.errors);
            }
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form className="pb-3 pt-3">
            <div className="mb-3 pb-5">
              <label htmlFor="email" className="form-label">
                Email*
              </label>
              <Field
                className="form-control"
                id="email"
                name="email"
                aria-describedby="emailHelp"
                required
              />
            </div>

            {backendErrors && (
              <div className="alert alert-danger">
                {backendErrors.map((error, index) => (
                  <div key={index}>{error.msg}</div>
                ))}
              </div>
            )}
            <div className="row ">
              <div className="col text-end d-flex justify-content-around">
                <button
                  type="submit"
                  className="btn background_dark text-white"
                  disabled={isSubmitting}
                >
                  Réinitialiser
                </button>
              </div>
              <div className="col d-flex justify-content-around">
                <button className="btn background_dark text-white">
                  <Link className="text-white text-decoration-none" to="/login">
                    Annuler
                  </Link>
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ForgotPassword;
