import React from "react";
import foxynArt from "../assets/FoxynArt.jpg";
import troisPtitsChats from "../assets/3ptitschats76.png";
import auFilDesChiens from "../assets/aufildeschiens.jpg";

function Partenaires(props) {
  return (
    <section>
      <div className="col text-center m-5">
        <h2>Mes partenariats</h2>
      </div>

      <div className="col mt-3 p-2 rounded-border align-items-center shadow">
        <div className="d-flex flex-wrap col-md-10 col-xl-10 mx-auto justify-content-around m-2 p-3">
          <div className="p-4 text-center col-12 col-md-4">
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.foxynart.com/personnalisation"
              className="link-underline link-underline-opacity-0"
            >
              <img
                className="partenaire pb-2"
                src={foxynArt}
                alt="logo de Foxyn Art"
              />
              <p className="d-block marine_zooethos fw-bold ">FoxynArt</p>
            </a>
            <div>
              code promo : <br /> <strong>ZOOETHOS</strong>{" "}
            </div>
            <div>-10% sur la première commande de print</div>
          </div>

          <div className="p-4 text-center col-12 col-md-4">
            <a
              rel="noreferrer"
              target="_blank"
              className="link-underline link-underline-opacity-0"
              href="https://www.facebook.com/p/3-ptits-chats-76-100079630045577/"
            >
              <img
                className="partenaire pb-2"
                src={troisPtitsChats}
                alt="logo de 3 P'tits Chats 76"
              />

              <p className="d-block fw-bold marine_zooethos ">
                3 p'tits chats 76
              </p>
            </a>

            <div>10% de remise sur la première prestation</div>
          </div>

          <div className="p-4 text-center col-12 col-md-4">
            <a
              rel="noreferrer"
              target="_blank"
              className="link-underline link-underline-opacity-0"
              href="https://aufildeschiens29.com/"
            >
              <img
                className="partenaire pb-2 "
                src={auFilDesChiens}
                alt="logo de Au Fil des Chiens et Chats"
              />

              <p className="d-block marine_zooethos fw-bold ">
                Au Fil des Chiens
              </p>
            </a>
            <div className="marine_zooethos">
              code promo : <strong>ZOOETHOS10</strong>{" "}
            </div>
            <div className="marine_zooethos">
              -10% de remise sur TOUT le site dès 45€ d'achat
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Partenaires;
