import React from "react";

function AdminInformation(props) {
  return (
    <div className="mt-4 ">
      <div className="mt-5 align-items-center">
        <h3 className="mt-4">Mes informations</h3>
        <div className="rounded-border background_blue col-lg-10 justify-content-center p-3">
          <div className="mt-2 mb-2">
            <h4 className="fs-6 ms-1">Adresse e-mail</h4>
            <div className="ms-1 me-1 row justify-content-between">
              <div className="col-8 background_white rounded-border">test</div>
              <div className="col-4 text-end p-0">
                <button type="button" className="btn btn-light ">
                  Changer
                </button>
              </div>
            </div>
          </div>
          <div className="mt-2 ">
            <h4 className="fs-6 ms-1">Mot de passe</h4>
            <div className="ms-1 me-1 row justify-content-between">
              <div className="col-8 background_white rounded-border">test</div>
              <div className="col-4 text-end p-0">
                <button type="button" className="btn btn-light">
                  Changer
                </button>
              </div>
            </div>
          </div>
          <div className="mt-2">
            <h4 className="fs-6 ms-1">Numéro de téléphone</h4>
            <div className="ms-1 me-1 row justify-content-between">
              <div className="col-8 background_white rounded-border">test</div>
              <div className="col-4 text-end p-0">
                <button type="button" className="btn btn-light">
                  Changer
                </button>
              </div>
            </div>
          </div>
          <div className="mt-2">
            <h4 className="fs-6 ms-1">Facebook</h4>
            <div className="ms-1 me-1 row justify-content-between">
              <div className="col-8 background_white rounded-border">test</div>
              <div className="col-4 text-end p-0">
                <button type="button" className="btn btn-light">
                  Changer
                </button>
              </div>
            </div>
          </div>
          <div className="mt-2">
            <h4 className="fs-6 ms-1">Instagram</h4>
            <div className="ms-1 me-1 row justify-content-between">
              <div className="col-8 background_white rounded-border">test</div>
              <div className="col-4 text-end p-0">
                <button type="button" className="btn btn-light">
                  Changer
                </button>
              </div>
            </div>
          </div>
          <div className="mt-2">
            <h4 className="fs-6 ms-1">TikTok</h4>
            <div className="ms-1 me-1 row justify-content-between">
              <div className="col-8 background_white rounded-border">test</div>
              <div className="col-4 text-end p-0">
                <button type="button" className="btn btn-light">
                  Changer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminInformation;
